import React from "react";
import Countdown from "react-countdown";
import { motion } from "framer-motion";
import "./ComingSoon.css";
import instagram from "../assets/images/instagram.png";
import youtube from "../assets/images/youtube.png";

const ComingSoon = () => {
  const launchDate = new Date("2024-12-01T12:00:00Z").getTime(); 
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="coming-soon-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
     
        <>
             
             <div className="loader-container">
  <div className="loader"></div>
  <h1 className="loader-text">Coming Soon</h1>
</div>

         
          <motion.div className="countdown" variants={itemVariants}>
            <p>Our website will be live in:</p>
           <Countdown
          date={launchDate}
          renderer={({ days, hours, minutes, seconds }) => (
            <div>
              <span>{days}d </span>
              <span>{hours}h </span>
              <span>{minutes}m </span>
              <span>{seconds}s</span>
            </div>
          )}
        />
          </motion.div>

         
          <motion.div className="social-links" variants={itemVariants}>
            <p>Follow us for updates:</p>
            <a
              href="https://www.instagram.com/smakcoders"
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.img
                src={instagram}
                alt="Instagram"
                whileHover={{ scale: 1.2, rotate: 10 }}
              />
            </a>
            <a
              href="https://youtube.com/@smakcoders-e4r?si=ro0heD4hYPs8wX2q"
              target="_blank"
              rel="noopener noreferrer"
            >
              <motion.img
                src={youtube}
                alt="YouTube"
                whileHover={{ scale: 1.2, rotate: -10 }}
              />
            </a>
          </motion.div>
        </>
      
    </motion.div>
  );
};

export default ComingSoon;
